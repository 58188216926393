/*
    COPYRIGHT ABSOLUMENT OUI (ORIGINAL PAR SOCIAL GARE)
    Ce fichier est prot�g� par la propri�t�
    intellectuelle en France et dans d'autres pays
    
    Toute reproduction (m�me partielle), utilisation
    ou modification sans l'accord de son cr�ateur
    constitue un d�lit. 
*/



/* -- compass -- */


/* -- sass files -- */


/* -- colors -- */


/* -- colors (screen) -- */


/* -- widths -- */


/* -- base -- */

@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,100italic,300italic");

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    overflow-x: hidden;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

body {
    font-family: 'Achemine';
    font-size: 1vh;
    font-weight: normal;
    line-height: 1;
}

*,
:after,
:before {
    outline: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:focus,
*:active,
:after:focus,
:after:active,
:before:focus,
:before:active {
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}

.rows {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    -moz-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
}

.rows .row-group {
    height: 10%;
}

.rows .row-group:hover {
    cursor: default;
}

.rows .row-group>.row {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    color: #fff;
}

.rows .row-group>.row>div {
    display: table-cell;
    overflow: hidden;
    line-height: 1.2;
    vertical-align: text-bottom;
}


/* -- sizes -- */

.rows .row-group.row-group-2 {
    height: 20%;
}

.rows .row-group.row-group-10 {
    height: 100%;
}


/* -- bar -- */

.row-group-bar {
    position: absolute;
    bottom: 0;
    height: 10%;
    padding-left: 2vh;
}

.row-group-bar.row-group-bar-yellow {
    background: #ffbf51 !important;
}

.row-group-bar.row-group-bar-yellow .text-scroll-x {
    color: #003a79;
    font-style: normal !important;
}

.row-group-bar.row-group-bar-red {
    background: #cd0037 !important;
}

.row-group-bar.row-group-bar-red .text-scroll-x {
    color: #ffffff;
    font-style: normal !important;
}

.row-group-bar .col-first {
    width: auto !important;
    vertical-align: middle !important;
}

.row-group-bar .col-first .bar-informations {
    font-size: 7em;
}

.row-group-bar .col-second {
    width: 30vh;
    text-align: center;
    vertical-align: bottom !important;
}

/* -- rer --*/

.row-rer .col-first {
    position: relative;
    width: 100%;
    height: 30vh;
    padding: 2.5vh;
    vertical-align: top;
    background: #fff;
}

.row-rer .col-second {
    position: relative;
    width: 100%;
    height: 60vh;
    padding: 2.5vh;
    vertical-align: top;
    background: #004394;
}

.rer.text-station {
    font-size: 8.7em;
    font-weight: 800;
    display: inline-block;
    color: #003A79;
}

.rer.text-mision {
    color: #003a79 !important;
    font-size: 6.5em;
    font-weight: 600;
    font-family: 'Avenir';
    display: inline-block;
}

.rer.text-time-hours {
    color: #003A79 !important;
    font-size: 6.5em;
    font-weight: 800;
    font-family: 'Achemine';
    display: inline-block;
}

.rer.text-train-length {
    color: #003A79 !important;
    font-size: 6.5em;
    font-weight: 800;
    font-family: 'Achemine';
    display: inline-block;
}

/* -- selection -- */

::selection {
    color: white;
    background: #006f94;
}

::-moz-selection {
    color: white;
    background: #006f94;
}

.alert {
    position: relative;
    margin-bottom: 20px;
    padding: 20px 20px 20px 80px;
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 1.3;
    background: #e74c3c;
}

.alert.alert-flash {
    position: fixed;
    top: 7px;
    right: 0;
    margin-bottom: 0;
    z-index: 5000;
    animation: alert 4s ease forwards;
}

.alert.alert-success {
    background: #16a085;
}

.alert.alert-success .icon {
    background: #148e76;
}

.alert.alert-success .icon:before {
    content: '\e116';
}

.alert.alert-info {
    color: #878787;
    background: #ededed;
}

.alert.alert-info .icon {
    background: #e0e0e0;
}

.alert.alert-info .icon:before {
    content: '\e60a';
}

.alert .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    padding: 22px 0;
    font-family: 'icomoon';
    text-align: center;
    background: #d62c1a;
}

.alert .icon:before {
    content: '\e117';
}

@keyframes alert {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    10% {
        opacity: 1;
        transform: translateX(0%);
    }

    90% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

.sidebar {
    position: fixed;
    z-index: 5001;
    height: 100%;
    overflow: hidden;
    color: #fff;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.4;
    background: #1c1d26;
    -moz-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    -o-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    -webkit-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
}

.sidebar.sidebar-left {
    width: 76px;
    left: -76px;
}

.sidebar.sidebar-left:hover {
    left: 0;
}

.sidebar.sidebar-left:hover~.rows {
    left: 76px;
}

.sidebar.sidebar-right {
    width: 450px;
    right: -450px;
}

.sidebar .sidebar-toggle,
.sidebar .sidebar-header {
    display: block;
    height: 76px;
    line-height: 76px;
    margin-bottom: 5px;
    text-align: center;
    background: #006f94;
}

.sidebar .sidebar-toggle.sidebar-toggle,
.sidebar .sidebar-header.sidebar-toggle {
    position: absolute;
    top: 0;
    z-index: 5000;
    width: 100%;
    font-size: 1.8em;
    visibility: visible;
    opacity: 1;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.sidebar .sidebar-inner {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.sidebar .sidebar-inner li {
    width: 450px;
    position: relative;
}

.sidebar .sidebar-inner li.sidebar-header a {
    padding: 0;
    color: #fff;
    font-size: 1.3em;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: bold;
}

.sidebar .sidebar-inner li.sidebar-title {
    padding: 20px 40px;
    font-size: 1.2em;
    font-weight: 500;
}

.sidebar .sidebar-inner li a {
    display: block;
    padding: 15px 40px;
    color: rgba(250, 250, 250, 0.8);
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.sidebar .sidebar-inner li a:hover,
.sidebar .sidebar-inner li a:focus {
    color: #fff;
}

.sidebar .sidebar-inner li span[class^="icon-"],
.sidebar .sidebar-inner li a.sidebar-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 30px;
}

.sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
}


/* -- sidebar-active -- */

.sidebar-active {
    position: fixed;
    z-index: 4000;
    top: 0;
    left: 0;
    width: 76px;
    height: 100%;
}

.sidebar-active:hover~.sidebar-left {
    left: 0;
}

.sidebar-active:hover~.rows {
    left: 76px;
}


/* -- toggleSidebar -- */

.toggle-left .sidebar-left {
    left: 0 !important;
    width: 450px;
}

.toggle-left .sidebar-left .sidebar-toggle {
    visibility: hidden !important;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.toggle-left .sidebar-left .sidebar-inner {
    visibility: visible;
    opacity: 1;
}

.toggle-left .sidebar-overlay {
    display: block;
}

.toggle-left .rows {
    left: 450px !important;
}

.toggle-right .sidebar-right {
    right: 0 !important;
}

.toggle-right .sidebar-right .sidebar-toggle {
    visibility: hidden !important;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.toggle-right .sidebar-right .sidebar-inner {
    visibility: visible;
    opacity: 1;
}

.toggle-right .sidebar-overlay {
    display: block;
}

.toggle-right .rows {
    left: -450px !important;
}


/* -- informations -- */

.bar-informations {
    display: inline-block;
    white-space: nowrap;
    font-style: italic;
}


/* -- clock -- */

.bar-clock {
    display: inline-block;
    position: relative;
    z-index: 5000;
    width: 80%;
    height: 7.5vh;
    font-size: 5vh;
    font-weight: bold;
    line-height: 1.5;
    border: solid 0.5vh #fff;
    border-bottom: none;
    border-radius: 0.8vh 0.8vh 0 0;
    background: #003a79;
}

.bar-clock small {
    display: inline-block;
    color: #ff9f17;
}


/* -- blink -- */

.animation-blink {
    position: relative;
}

.animation-blink .animation-blink-1,
.animation-blink .animation-blink-2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    will-change: visibility;
    animation: blink 4s steps(2, start) infinite;
    -webkit-animation: blink 4s steps(2, start) infinite;
}

.animation-blink .animation-blink-2 {
    visibility: hidden;
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
}

.animation-blink .animation-blink-clock {
    animation: blink 1.5s steps(2, start) infinite;
    -webkit-animation: blink 1.5s steps(2, start) infinite;
}

@keyframes blink {
    from {
        visibility: visible;
    }

    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink {
    from {
        visibility: visible;
    }

    to {
        visibility: hidden;
    }
}


/* -- scroll-x -- */

.animation-scroll-x {
    transform: translateZ(0);
    will-change: transform;
    animation: scrollX 0s linear infinite;
    -webkit-animation: scrollX 0s linear infinite;
}

@keyframes scrollX {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes scrollX {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}


/* -- scroll-y -- */

.row-group-informations .scroll-y {
    transform: translateZ(0);
    will-change: transform;
    position: absolute;
}

.row-group-train .scroll-y {
    transform: translateZ(0);
    will-change: transform;
    position: relative;
}


/* -- texts --*/

.text-time-hours {
    display: inline-block;
    font-size: 9.8em;
    font-weight: 800;
}

.text-time-ontime {
    display: inline-block;
    font-size: 4em;
    font-weight: bold;
}

.text-time-features {
    font-size: 5.5em;
    font-weight: bold;
}

.text-station {
    line-height: 1;
    font-size: 8.7em;
    font-weight: 800;
    display: inline-block;
}

.text-station.text-station-reduced {
    font-size: 6.7em;
}

.text-station.text-station-reduced.text-station-reduced-nomargin {
    margin-top: 0;
}

.quai.text-type {
    color: #003a79 !important;
    font-size: 4.7em;
    font-family: 'Achemine';
    display: inline-block;
}

.quai.text-number {
    display: inline-block;
    color: #9397ac;
    font-size: 4em;
    font-weight: bold;
}


/* -- background -- */

.row-background.quai {
    position: absolute !important;
    right: 0;
    bottom: 0;
    width: 19vh;
    height: 64vh;
    background-repeat: no-repeat;
    z-index: 0;
}


/* -- cols -- */

.rows .row-group.row-group-train {
    height: 100%;
}

.rows .row-group.row-group-train .row:nth-of-type(1) {
    height: 90%;
}

.rows .row-group.row-group-train .row:nth-of-type(1) .col-first {
    position: relative;
    width: 62vh;
    height: 100%;
    padding: 2.5vh;
    vertical-align: top;
    background: #fff;
}

.rows .row-group.row-group-train .row:nth-of-type(1) .col-first>* {
    position: relative;
    z-index: 100;
}

.rows .row-group.row-group-train .row:nth-of-type(1) .col-second .col-second-container {
    height: 90vh;
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.rows .row-group.row-group-train .row:nth-of-type(2) .col-third {
    background: #51124a;
}

.rows .row-group.row-group-train.row-group-train-third .row:nth-of-type(2) {
    height: 25%;
}

.rows .row-group.row-group-train.row-group-train-third .row:nth-of-type(1) {
    height: 65%;
}

.rows .row-group.row-group-train.row-group-train-third .row:nth-of-type(1) .col-second-container {
    height: 65vh;
}


/* -- bar -- */

.row-group-bar {
    height: 11%;
}

.row-group-bar-purple {
    background: #51124a !important;
}


/* -- styles -- */

.rows.rows-departures .row-background.row-background-quai,
.rows.rows-transilien .row-background.row-background-quai {
    background-image: url(../../../img/screen/quai/row-background-departure.svg);
}

.rows.rows-departures .row-group-bar.quai,
.rows.rows-transilien .row-group-bar.quai {
    background: #004394;
}

.rows.rows-departures .row-group-train .col-first,
.rows.rows-transilien .row-group-train .col-first {
    color: #003a79;
}

.rows.rows-departures .row-group-train .col-second,
.rows.rows-transilien .row-group-train .col-second {
    background: #004394;
}

.rows.rows-departures .row-group-train .col-second .train-stations tbody td.trains-stations-column,
.rows.rows-departures .row-group-train .col-second .train-stations tbody td.trains-stations-column-blank,
.rows.rows-transilien .row-group-train .col-second .train-stations tbody td.trains-stations-column,
.rows.rows-transilien .row-group-train .col-second .train-stations tbody td.trains-stations-column-blank {
    background: #6969af;
}

.rows.rows-departures .row-group-train .col-second .train-stations tbody .train-stations-margin-background,
.rows.rows-transilien .row-group-train .col-second .train-stations tbody .train-stations-margin-background {
    background-image: url(../../../img/screen/quai/train-stations-margin-background-departure.svg);
}

.rows.rows-arrivals .row-background.row-background-quai {
    background-image: url(../../../img/screen/quai/row-background-arrival.svg);
}

.rows.rows-arrivals .row-group-bar {
    background: #217a42;
}

.rows.rows-arrivals .row-group-train .col-first {
    color: #217a42;
}

.rows.rows-arrivals .row-group-train .col-second {
    background: #217a42;
}

.rows.rows-arrivals .row-group-train .col-second .train-stations tbody td.trains-stations-column,
.rows.rows-arrivals .row-group-train .col-second .train-stations tbody td.trains-stations-column-blank {
    background: #76a56b;
}

.rows.rows-arrivals .row-group-train .col-second .train-stations tbody .train-stations-margin-background {
    background-image: url(../../../img/screen/quai/train-stations-margin-background-arrival.svg);
}


/* -- logo -- */

.quai.train-logo {
    width: 10vh;
    height: 5vh;
    margin-bottom: 2vh;
    background: no-repeat center;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

.rer.train-logo {
    width: 20vh;
    height: 10vh;
    margin-bottom: 2vh;
    background: no-repeat center;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
    display: inline-block;
}

.train-logo.train-logo-sncf-color {
    background-image: url(../../../img/screen/quai/logo-sncf-color.svg);
}

.train-logo.train-logo-inoui-color {
    background-image: url(../../../img/screen/quai/logo-inoui-color.svg);
}

.train-logo.train-logo-ter-color {
    background-image: url(../../../img/screen/quai/logo-ter-color.svg);
}

.train-logo.train-logo-tgv-color {
    background-image: url(../../../img/screen/quai/logo-tgv-color.svg);
}

.train-logo.train-logo-ice-color {
    background-image: url(../../../img/screen/quai/logo-ice-color.svg);
}

.train-logo.train-logo-lyria-color {
    background-image: url(../../../img/screen/quai/logo-lyria-color.svg);
}

.train-logo.train-logo-fluo-color {
    background-image: url(../../../img/screen/quai/logo-fluo-color.svg);
}

.train-logo.train-logo-occitanie-color {
    background-image: url(../../../img/screen/quai/logo-occitanie-color.svg);
}

.train-logo.train-logo-intercite-color {
    background-image: url(../../../img/screen/quai/logo-intercite-color.svg);
}

.train-logo.train-logo-ouigo-color {
    background-image: url(../../../img/screen/quai/logo-ouigo-color.svg);
}

.train-logo.train-logo-ouigo-classique-color {
    background-image: url(../../../img/screen/quai/logo-ouigo-classique-color.svg);
}

/*.train-logo.train-logo-ler {
    background-image: url(../../img/screen/common/logo-ler.svg);
}*/

.train-logo.train-logo-aleop-color {
    background-image: url(../../../img/screen/quai/logo-aleop-color.svg);
}

.train-logo.train-logo-auvergne-rhone-alpes-color {
    background-image: url(../../../img/screen/quai/logo-auvergerhonealpes-color.svg);
}

.train-logo.train-logo-breizhgo-color {
    background-image: url(../../../img/screen/quai/logo-breizhgo-color.svg);
}

.train-logo.train-logo-db-color {
    background-image: url(../../../img/screen/quai/logo-db-color.svg);
}

.train-logo.train-logo-ter-hauts-de-france-color {
    background-image: url(../../../img/screen/quai/logo-hautsdefrance-color.svg);
}

.train-logo.train-logo-lio-color {
    background-image: url(../../../img/screen/quai/logo-lio-color.svg);
}

.train-logo.train-logo-ter-metrolor-color {
    background-image: url(../../../img/screen/quai/logo-metrolor-color.svg);
}

.train-logo.train-logo-mobigo-color {
    background-image: url(../../../img/screen/quai/logo-mobigo-color.svg);
}

.train-logo.train-logo-nomad-color {
    background-image: url(../../../img/screen/quai/logo-nomad-color.svg);
}

.train-logo.train-logo-remi-color {
    background-image: url(../../../img/screen/quai/logo-remi-color.svg);
}

.train-logo.train-logo-renfe-ave-color {
    background-image: url(../../../img/screen/quai/logo-renfeave-color.svg);
}

.train-logo.train-logo-sbb-color {
    background-image: url(../../../img/screen/quai/logo-sbb-color.svg);
}

.train-logo.train-logo-sncf-1985-color {
    background-image: url(../../../img/screen/quai/logo-sncf1985-color.svg);
}

.train-logo.train-logo-sncf-1992-color {
    background-image: url(../../../img/screen/quai/logo-sncf1992-color.svg);
}

.train-logo.train-logo-ter-alsace-color {
    background-image: url(../../../img/screen/quai/logo-teralsace-color.svg);
}

.train-logo.train-logo-ter-aquitaine-color {
    background-image: url(../../../img/screen/quai/logo-teraquitaine-color.svg);
}

.train-logo.train-logo-ter-basse-normandie-color {
    background-image: url(../../../img/screen/quai/logo-terbassenormandie-color.svg);
}

.train-logo.train-logo-ter-bourgogne-color {
    background-image: url(../../../img/screen/quai/logo-terbourgogne-color.svg);
}

.train-logo.train-logo-ter-bretagne-color {
    background-image: url(../../../img/screen/quai/logo-terbretagne-color.svg);
}

.train-logo.train-logo-ter-centre-color {
    background-image: url(../../../img/screen/quai/logo-tercentre-color.svg);
}

.train-logo.train-logo-ter-languedoc-roussillon-color {
    background-image: url(../../../img/screen/quai/logo-terlanguedocroussillon-color.svg);
}

.train-logo.train-logo-ter-midi-pyrenees-color {
    background-image: url(../../../img/screen/quai/logo-termidipyrenees-color.svg);
}

.train-logo.train-logo-ter-nord-pas-de-calais-color {
    background-image: url(../../../img/screen/quai/logo-ternordpasdecalais-color.svg);
}

.train-logo.train-logo-ter-poitou-charentes-color {
    background-image: url(../../../img/screen/quai/logo-terpoitoucharentes-color.svg);
}

.train-logo.train-logo-thello-color {
    background-image: url(../../../img/screen/quai/logo-thello-color.svg);
}

.train-logo.train-logo-tram-train-color {
    background-image: url(../../../img/screen/quai/logo-tramtrain-color.svg);
}

.train-logo.train-logo-zou-color {
    background-image: url(../../../img/screen/quai/logo-zou-color.svg);
}

.train-logo.train-logo-eurostar-color {
    background-image: url(../../../img/screen/quai/logo-eurostar-color.svg);
}

.train-logo.train-logo-thalys-color {
    background-image: url(../../../img/screen/quai/logo-thalys-color.svg);
}

.train-logo.train-logo-rer-a-color {
    background-image: url(../../../img/screen/rer/logo-rer-a-color.svg);
}

.train-logo.train-logo-rer-b-color {
    background-image: url(../../../img/screen/rer/logo-rer-b-color.svg);
}

.train-logo.train-logo-rer-c-color {
    background-image: url(../../../img/screen/rer/logo-rer-c-color.svg);
}

.train-logo.train-logo-rer-d-color {
    background-image: url(../../../img/screen/rer/logo-rer-d-color.svg);
}

.train-logo.train-logo-rer-e-color {
    background-image: url(../../../img/screen/rer/logo-rer-e-color.svg);
}

.train-logo.train-logo-t-h-color {
    background-image: url(../../../img/screen/common/logo-t-h.svg);
}

.train-logo.train-logo-t-j-color {
    background-image: url(../../../img/screen/common/logo-t-j.svg);
}

.train-logo.train-logo-t-k-color {
    background-image: url(../../../img/screen/common/logo-t-k.svg);
}

.train-logo.train-logo-t-l-color {
    background-image: url(../../../img/screen/common/logo-t-l.svg);
}

.train-logo.train-logo-t-n-color {
    background-image: url(../../../img/screen/common/logo-t-n.svg);
}

.train-logo.train-logo-t-p-color {
    background-image: url(../../../img/screen/common/logo-t-p.svg);
}

.train-logo.train-logo-t-r-color {
    background-image: url(../../../img/screen/common/logo-t-r.svg);
}

.train-logo.train-logo-t-u-color {
    background-image: url(../../../img/screen/common/logo-t-u.svg);
}

.train-logo.train-logo-lunea-color {
    background-image: url(../../../img/screen/quai/logo-lunea-color.svg);
}

.train-logo.train-logo-teoz-color {
    background-image: url(../../../img/screen/quai/logo-teoz-color.svg);
}

.train-logo.train-logo-frecciarossa-color {
    background-image: url(../../../img/screen/quai/logo-frecciarossa-color.svg);
}

.train-logo.train-logo-trenitalia-color {
    background-image: url(../../../img/screen/quai/logo-trenitalia-color.svg);
}

.train-logo.train-logo-cfl-color {
    background-image: url(../../../img/screen/quai/logo-cfl-color.svg);
}

.train-logo.train-logo-sncb-color {
    background-image: url(../../../img/screen/quai/logo-sncb-color.svg);
}

/* -- time -- */

.train-time .text-time-hours {
    width: 34vh;
}

.train-time .animation-blink {
    height: 12vh;
}

.train-time .animation-blink .animation-blink-1,
.train-time .animation-blink .animation-blink-2,
.train-time .animation-blink .animation-blink-2 {
    top: auto;
    bottom: 0;
    transform: none;
}

.train-time .animation-blink .animation-blink-2 {
    line-height: 1.2;
    transform: translateY(-20%);
}


/* -- station -- */

.train-station {
    margin-top: 2vh;
}

.trains-station td.trains-station-column {
    align-content: center;
    top: 50%;
}


/* -- informations -- */

.train-informations {
    margin-top: 1vh;
}

.train-informations .text-number {
    margin-left: 2vh;
}

.train-informations.train-informations-transilien .text-type {
    font-size: 2.7em !important;
    vertical-align: bottom;
}

.train-informations.train-informations-transilien .text-type .text-type-logo {
    width: 9vh;
    vertical-align: middle;
}


/* -- options -- */

.train-options {
    position: absolute !important;
    bottom: 0;
    left: 0;
}

.train-options .train-options-logo {
    display: inline-block;
    width: 8vh;
    height: 8vh;
    background: no-repeat center;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

/*.train-options .train-options-logo.train-options-logo-pmr {
    background-image: url(../../img/screen/options/option-pmr.svg);
}

.train-options .train-options-logo.train-options-logo-bikes {
    background-image: url(../../img/screen/options/option-bikes.svg);
}

.train-options .train-options-logo.train-options-logo-restoration {
    background-image: url(../../img/screen/options/option-restoration.svg);
}

.train-options .train-options-logo.train-options-logo-bar {
    background-image: url(../../img/screen/options/option-bar.svg);
}*/


/* -- stations -- */

.train-stations-arrivals-head {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 3.5vh 0;
    padding-left: 12vh;
    color: #f5f900;
    font-size: 5.5em;
    background: #217a42;
}

.train-stations {
    border-collapse: collapse;
    border-spacing: 0;
    height: 75%;
    margin-left: 3vh;
    font-size: 6em;
}

.train-stations.train-stations-solo {
    height: 60%;
}

.train-stations tr.train-stations-arrivals-margin td {
    height: 14vh;
    padding: 0;
}

.train-stations td {
    padding: 0;
    vertical-align: bottom;
}

.train-stations td.trains-stations-column {
    width: 3vh;
}

.train-stations td.trains-stations-column:before {
    content: '';
    display: inline-block;
    width: 2.5vh;
    height: 2.5vh;
    margin: 0 0.4vh;
    margin-bottom: 50%;
    border-radius: 50%;
    background: #f5f900;
}

.train-stations td.trains-stations-column.trains-stations-column-orange:before {
    background: #ff9f17;
}

.train-stations td.trains-stations-station span {
    display: inline-block;
    padding-left: 3vh;
}

.train-stations td.trains-stations-station span img {
    position: relative;
    top: 1vh;
    width: 5vh;
    margin-left: 2vh;
}

.train-stations td.trains-stations-station.trains-stations-title {
    line-height: 0.5;
    font-weight: bold;
}

.train-stations td.trains-stations-station.trains-stations-title.trains-stations-title-yellow {
    color: #f5f900;
}

.train-stations td.trains-stations-station.trains-stations-title.trains-stations-title-orange {
    color: #ff9f17;
}

.train-stations td.trains-stations-station.trains-stations-title span {
    font-size: 0.55em;
    vertical-align: top;
}

.train-stations td.train-stations-margin {
    width: 14vh;
}

.train-stations td.train-stations-margin.train-stations-margin-background {
    height: 9.5vh;
    background: no-repeat center;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

.train-stations tr:first-child td {
    padding-top: 2.5vh;
}

.train-stations tr.train-stations-last-departures td.trains-stations-column {
    border-bottom-left-radius: 0.6vh;
}

.train-stations tr.train-stations-last-departures td.trains-stations-station {
    border-bottom-left-radius: 0.6vh;
}

.train-stations tr.train-stations-last-arrivals td.trains-stations-column {
    border-bottom-left-radius: 0.6vh;
}

.train-stations tr.train-stations-last-arrivals td.trains-stations-station {
    border-bottom-left-radius: 0.6vh;
}

.train-stations tr.train-stations-last-departures td.trains-stations-station span {
    padding-right: 2vh;
    font-weight: bold;
    border-radius: 0 0.6vh 0.6vh 0;
    background: #6969af;
}

.train-stations tr.train-stations-last-arrivals td.trains-stations-station span {
    padding-right: 2vh;
    font-weight: bold;
    border-radius: 0 0.6vh 0.6vh 0;
    background: #76a56b;
}

.rows-arrivals .train-stations.train-stations-reduced {
    height: auto;
}


/* -- track -- */

.train-wagons-track {
    position: absolute;
    top: 3.5vh;
    left: 2vh;
    text-align: center;
}

.train-wagons-track .train-track-title {
    display: inline-block;
    padding: 0 1.8vh;
    color: #51124a;
    font-size: 2em;
    line-height: 1;
    border-radius: 1vh;
    background: #fff;
}

.train-wagons-track .train-track-track {
    font-size: 7em;
    font-weight: bold;
}

.train-wagons-track .train-track-bottom {
    width: 10vh;
    height: 0.5vh;
    border-radius: 1vh;
    background: #fff;
}


/* -- wagons -- */

.train-wagons {
    position: relative;
    width: 100%;
    height: 100%;
}

.train-wagons .train-wagons-align.train-wagons-align-left {
    position: absolute;
    left: 20vh;
    transform: none;
}

.train-wagons .train-wagons-align.train-wagons-align-right {
    position: absolute;
    right: 20vh;
    left: auto;
    transform: none;
}

.train-wagons .train-wagons-trains {
    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.train-wagons .train-wagons-trains.train-wagons-trains-reduced .train-wagons-train .train-wagons-train-wagon {
    width: 6.2vh;
    height: 8vh;
    font-size: 3.8em;
}

.train-wagons .train-wagons-trains.train-wagons-trains-reduced .train-wagons-train .train-wagons-train-wagon.train-tgv-l {
    width: 7.1vh;
}

.train-wagons .train-wagons-trains.train-wagons-trains-reduced .train-wagons-train .train-wagons-train-wagon.train-tgv-r {
    width: 7.1vh;
}

.train-wagons .train-wagons-trains.train-wagons-trains-reduced .train-wagons-train .train-wagons-train-wagon.train-loco {
    width: 7.1vh;
}

.train-wagons .train-wagons-trains .train-wagons-train {
    display: inline-block;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-title {
    font-size: 4em;
    font-weight: bold;
    text-align: center;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-title.train-wagons-train-title-yellow {
    color: #f5f900;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-title.train-wagons-train-title-orange {
    color: #ff9f17;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon {
    display: inline-block;
    margin: 1vh 0.25vh 0 0.25vh;
    width: 8.2vh;
    height: 10vh;
    font-size: 5em;
    font-weight: bold;
    text-align: center;
    vertical-align: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon span {
    position: absolute;
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon.train-tgv-l {
    width: 9.1vh;
    background-image: url(../../../img/screen/wagons/tgv-l.svg);
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon.train-tgv-r {
    width: 9.1vh;
    background-image: url(../../../img/screen/wagons/tgv-r.svg);
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon.train-loco {
    width: 9.1vh;
    background-image: url(../../../img/screen/wagons/loco.svg);
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon.train-wagon {
    background-image: url(../../../img/screen/wagons/wagon.svg);
}

.train-wagons .train-wagons-trains .train-wagons-train .train-wagons-train-wagon.train-wagon-bar {
    background-image: url(../../../img/screen/wagons/wagon-bar.svg);
}

.train-wagons .train-wagons-line {
    position: absolute;
    top: 20vh;
    left: 2vh;
    right: 2vh;
    height: 0.5vh;
    background-size: 0.7%;
    background-image: url(../../../img/screen/wagons/train-wagons-line.svg);
}

.train-wagons .train-wagons-marks {
    position: absolute;
    top: 14.5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
}

.train-wagons .train-wagons-marks .train-wagons-marks-mark {
    position: absolute;
    width: 7vh;
    height: 7vh;
    transform: translateX(-50%);
    font-size: 6.3em;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    border: 0.3vh solid #fff;
    border-radius: 0.5vh;
    background: #003a79;
}

.train-wagons .train-wagons-marks .train-wagons-marks-mark.train-wagons-marks-mark-small {
    top: 1.5vh;
    width: 5.5vh;
    height: 5.5vh;
    font-size: 4.3em;
}

.train-wagons .train-wagons-marks .train-wagons-marks-mark.train-wagons-marks-mark-here {
    border: none;
    background: transparent url(../../../img/screen/wagons/here.svg) no-repeat center;
}