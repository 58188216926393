@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/icon-button/icon-button";
@use "@material/dialog";
@use "@material/ripple/mdc-ripple";
@use "@material/button/mdc-button";
@use "@material/button";
@use "@material/textfield";
@use "@material/floating-label/mdc-floating-label";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/card";
@use "@material/radio/styles" as radio;
@use "@material/form-field";
@use "@material/list/mdc-list";
@use "@material/list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/select/mdc-select";
@use "@material/chips/styles" as chips;
@use "@material/data-table/data-table";

@include icon-button.core-styles;
@include dialog.core-styles;
@include textfield.core-styles;
@include card.core-styles;
@include form-field.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

@import "material-icons/iconfont/material-icons.scss";
@import url(./assets/css/departs/screen/trains.css);
@import url(./assets/css/departs/screen/afl.css);
@import url(./assets/css/informations/screen/informations.css);
@import url(./assets/css/quai/screen/train.css);
@import url(./assets/fonts/Achemine/style.css);


body {
    height: 100%;
    width: 100%;
    background-color: #000;
    color: #f2f2f2;
}

.main-content {
    margin: 25px;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.app-bar {
    background-color: #333;
}

.mdc-top-app-bar__section img,
.mdc-top-app-bar__section .mdc-top-app-bar__title {
    cursor: pointer;
    color: #f2f2f2;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a:any-link {
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .logo {
        width: 100%;
    }
}

@media screen and (min-width: 801px) {
    .logo {
        width: 50%;
    }
}

.big-icon {
    font-size: 128px;
}

.center {
    text-align: center;
}

.inline-block {
    display: inline-block;
}

.primary {
    @include button.container-fill-color(#006f94);
}

.primary-dark {
    @include button.container-fill-color(#003a79);
}

.profile-pict {
    border-radius: 50%;
    margin: 0 auto;
}

.gare-card {
    @include card.fill-color(#003a79);
    width: 22%;
    min-width: 315px;
    display: inline-table;
    margin: 5px;
    line-height: 1.5;
}

.arrive {
    @include card.fill-color(#0c5639);
}

.gare-card__title {
    font-size: 1.5rem;
    font-weight: 500;
}

.gare-card__subtitle {
    font-size: 0.875rem;
    font-weight: 400;
}

.gare-card__button.depart {
    @include button.ink-color(#0080aa);
}

.gare-card__button.arrive {
    @include button.ink-color(#217a42);
}

.full-width {
    width: 100%;
}

.menu-item {
    height: 48px;
}

.menu-item-text {
    margin: auto 0;
}

.menu-item-icon {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.menu-item.departs {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_departs.png);
    height: 16px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

.menu-item.arrives {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_arrives.png);
    height: 16px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

.menu-item.infos {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_infos.png);
    height: 16px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

.menu-item.quai-depart {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_quai_departs.png);
    height: 16px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

.menu-item.quai-arrive {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_quai_arrives.png);
    height: 16px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

.menu-item.control-panel {
    margin: auto 0;
    background-image: url(./assets/img/common/screen_controlpanel.png);
    height: 14px;
    width: 24px;
    background-size: cover;
    margin-right: 5px;
}

#root {
    height: 100%;
    width: 100%;
}

.gare-card-media-sncf {
    background-image: url(./assets/img/common/SNCF.svg);
    background-size: 50%;
    padding-top: 56.25%;
}

.gare-card-media-sncf::before {
    margin-top: 0;
}

.gare-card-media-ratp {
    background-image: url(./assets/img/common/RATP.svg);
    background-size: 25%;
    padding-top: 56.25%;
}

.gare-card-media-ratp::before {
    margin-top: 0;
}

.train-card {
    padding-top: 56.25%;
    background-size: 50%;
}

.train-card::before {
    margin-top: 0;
}

.train-card-sncf {
    background-image: url(./assets/img/common/SNCF.svg);
}

.train-card-inoui {
    background-image: url(./assets/img/screen/quai/logo-inoui-color.svg);
}

.train-card-ter {
    background-image: url(./assets/img/screen/quai/logo-ter-color.svg);
}

.train-card-tgv {
    background-image: url(./assets/img/screen/quai/logo-tgv-color.svg);
}

.train-card-ice {
    background-image: url(./assets/img/screen/quai/logo-ice-color.svg);
}

.train-card-lyria {
    background-image: url(./assets/img/screen/quai/logo-lyria-color.svg);
}

.train-card-fluo {
    background-image: url(./assets/img/screen/quai/logo-fluo-color.svg);
}

.train-card-occitanie {
    background-image: url(./assets/img/screen/quai/logo-occitanie-color.svg);
}

.train-card-intercite {
    background-image: url(./assets/img/screen/quai/logo-intercite-color.svg);
}

.train-card-ouigo {
    background-image: url(./assets/img/screen/quai/logo-ouigo-color.svg);
}

.train-card-ouigo-classique {
    background-image: url(./assets/img/screen/quai/logo-ouigo-classique-color.svg);
}

/*.train-card-ler {
    background-image: url(.train-card/.train-card/img/screen/common/logo-ler-color.svg);
}*/

.train-card-aleop {
    background-image: url(./assets/img/screen/quai/logo-aleop-color.svg);
}

.train-card-auvergne-rhone-alpes {
    background-image: url(./assets/img/screen/quai/logo-auvergerhonealpes-color.svg);
}

.train-card-breizhgo {
    background-image: url(./assets/img/screen/quai/logo-breizhgo-color.svg);
}

.train-card-db {
    background-image: url(./assets/img/screen/quai/logo-db-color.svg);
}

.train-card-ter-hauts-de-france {
    background-image: url(./assets/img/screen/quai/logo-hautsdefrance-color.svg);
}

.train-card-lio {
    background-image: url(./assets/img/screen/quai/logo-lio-color.svg);
}

.train-card-ter-metrolor {
    background-image: url(./assets/img/screen/quai/logo-metrolor-color.svg);
}

.train-card-mobigo {
    background-image: url(./assets/img/screen/quai/logo-mobigo-color.svg);
}

.train-card-nomad {
    background-image: url(./assets/img/screen/quai/logo-nomad-color.svg);
}

.train-card-remi {
    background-image: url(./assets/img/screen/quai/logo-remi-color.svg);
}

.train-card-renfe-ave {
    background-image: url(./assets/img/screen/quai/logo-renfeave-color.svg);
}

.train-card-sbb {
    background-image: url(./assets/img/screen/quai/logo-sbb-color.svg);
}

.train-card-sncf-1937 {
    background-image: url(./assets/img/screen/quai/logo-sncf1937-color.svg);
}

.train-card-sncf-1972 {
    background-image: url(./assets/img/screen/quai/logo-sncf1972-color.svg);
}

.train-card-sncf-1985 {
    background-image: url(./assets/img/screen/quai/logo-sncf1985-color.svg);
}

.train-card-sncf-1992 {
    background-image: url(./assets/img/screen/quai/logo-sncf1992-color.svg);
}

.train-card-ter-alsace {
    background-image: url(./assets/img/screen/quai/logo-teralsace-color.svg);
}

.train-card-ter-aquitaine {
    background-image: url(./assets/img/screen/quai/logo-teraquitaine-color.svg);
}

.train-card-ter-basse-normandie {
    background-image: url(./assets/img/screen/quai/logo-terbassenormandie-color.svg);
}

.train-card-ter-bourgogne {
    background-image: url(./assets/img/screen/quai/logo-terbourgogne-color.svg);
}

.train-card-ter-bretagne {
    background-image: url(./assets/img/screen/quai/logo-terbretagne-color.svg);
}

.train-card-ter-centre {
    background-image: url(./assets/img/screen/quai/logo-tercentre-color.svg);
}

.train-card-ter-languedoc-roussillon {
    background-image: url(./assets/img/screen/quai/logo-terlanguedocroussillon-color.svg);
}

.train-card-ter-midi-pyrenees {
    background-image: url(./assets/img/screen/quai/logo-termidipyrenees-color.svg);
}

.train-card-ter-nord-pas-de-calais {
    background-image: url(./assets/img/screen/quai/logo-ternordpasdecalais-color.svg);
}

.train-card-ter-poitou-charentes {
    background-image: url(./assets/img/screen/quai/logo-terpoitoucharentes-color.svg);
}

.train-card-thello {
    background-image: url(./assets/img/screen/quai/logo-thello-color.svg);
}

.train-card-tram-train {
    background-image: url(./assets/img/screen/quai/logo-tramtrain-color.svg);
}

.train-card-zou {
    background-image: url(./assets/img/screen/quai/logo-zou-color.svg);
}

.train-card-eurostar {
    background-image: url(./assets/img/screen/quai/logo-eurostar-color.svg);
}

.train-card-thalys {
    background-image: url(./assets/img/screen/quai/logo-thalys-color.svg);
}

.train-card-rer-a {
    background-image: url(./assets/img/screen/rer/logo-rer-a-color.svg);
}

.train-card-rer-b {
    background-image: url(./assets/img/screen/rer/logo-rer-b-color.svg);
}

.train-card-rer-c {
    background-image: url(./assets/img/screen/rer/logo-rer-c-color.svg);
}

.train-card-rer-d {
    background-image: url(./assets/img/screen/rer/logo-rer-d-color.svg);
}

.train-card-rer-e {
    background-image: url(./assets/img/screen/rer/logo-rer-e-color.svg);
}

.train-card-lunea {
    background-image: url(./assets/img/screen/quai/logo-lunea-color.svg);
}

.train-card-teoz {
    background-image: url(./assets/img/screen/quai/logo-teoz-color.svg);
}

.train-card-frecciarossa {
    background-image: url(./assets/img/screen/quai/logo-frecciarossa-color.svg);
}

.train-card-trenitalia {
    background-image: url(./assets/img/screen/quai/logo-trenitalia-color.svg);
}

.train-card-cfl {
    background-image: url(./assets/img/screen/quai/logo-cfl-color.svg);
}

.train-card-sncb {
    background-image: url(./assets/img/screen/quai/logo-sncb-color.svg);
}

.train-card-ter-franche-comte {
    background-image: url(./assets/img/screen/quai/logo-terfranchecomte-color.svg);
}


.train-card-sncf::before {
    margin-top: 0;
}

.quai {
    @import url(./assets/css/departs/screen/quai.css);
}

.edit-train-wagons {
    width: 9.1vh;
    height: 8vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: inline-block;
    cursor: pointer;
}

.edit-train-wagons.no-pointer {
    cursor: default;
}

.edit-train-wagons.train-loco {
    background-image: url(./assets/img/screen/wagons/loco.svg);
}

.edit-train-wagons.train-tgv-l {
    background-image: url(./assets/img/screen/wagons/tgv-l.svg);
}

.edit-train-wagons.train-tgv-r {
    background-image: url(./assets/img/screen/wagons/tgv-r.svg);
}

.edit-train-wagons.train-wagon {
    background-image: url(./assets/img/screen/wagons/wagon.svg);
}

.edit-train-wagons.train-wagon-bar {
    background-image: url(./assets/img/screen/wagons/wagon-bar.svg);
}

.edit-train-wagons.train-navette {
    background-image: url(./assets/img/screen/wagons/navette.svg);
    width: 16.8vh !important;
}

.inpage-form {
    @include textfield.ink-color(white);
    @include textfield.label-color(lightgrey);
    @include textfield.outline-color(grey);
    @include textfield.hover-outline-color(white);
}

.train-page-track {
    display: inline-block;
    width: 8vh;
    height: 8vh;
    font-size: 5vh;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    border: solid 0.35vh #fff;
    border-radius: 10%;
    z-index: 1000;
}

.train-page-hall {
    width: 8vh;
    height: 8vh;
    font-size: 5vh;
    font-weight: bold;
    text-align: center;
    border: solid 0.35vh #fff;
    border-radius: 10%;
    z-index: 1000;
    display: inline-block;
    line-height: 0.4;
    background: #fff;
}

.train-page-hall h1 {
    color: #000;
    margin: 0;
    font-size: 0.75em;
}

.train-page-hall small {
    color: #000;
    font-size: 0.4em;
}

.control-panel-row.depart {
    background-color: #003a79;
}

.control-panel-row.arrivee {
    background-color: #0c5639;
}

.control-panel-row.both {
    background: linear-gradient(90deg, rgba(0,58,121,1) 0%, rgba(12,86,57,1) 100%);
}

.departure-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-size: 100% 100%;
    animation: departureBackground 2s infinite steps(2, start);
}

@keyframes departureBackground {
    from {
        background-image: url(./assets/img/common/depart_en_cours.png);
    }
    to {
        background-image: url(./assets/img/common/depart_en_cours_2.png);
    }
}

/* Snow */
.snowflake {
    --size: 1vw;
    width: var(--size);
    height: var(--size);
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
}

@keyframes snowfall {
    0% {
        transform: translate3d(var(--left-ini), 0, 0);
    }

    100% {
        transform: translate3d(var(--left-end), 110vh, 0);
    }
}

@for $i from 1 through 50 {
    .snowflake:nth-child(#{$i}) {
        --size: #{random(5) * 0.2}vw;
        --left-ini: #{random(20) - 10}vw;
        --left-end: #{random(20) - 10}vw;
        left: #{random(100)}vw;
        animation: snowfall #{5 + random(10)}s linear infinite;
        animation-delay: -#{random(10)}s;
    }
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
    filter: blur(1px);
}

.snows {
    position: relative;
}