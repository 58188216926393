/* -- compass -- */
/* -- sass files -- */
/* -- colors -- */
/* -- colors (screen) -- */
/* -- widths -- */
/* -- base -- */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,100italic,300italic");

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overflow-x: hidden;
  -moz-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;
}

body {
  font-family: 'Achemine';
  font-size: 1vh;
  font-weight: normal;
  line-height: 1;
}

*, :after, :before {
  outline: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus, *:active, :after:focus, :after:active, :before:focus, :before:active {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.rows {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  -moz-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.rows .row-group {
  height: 10%;
}

.rows .row-group:hover {
  cursor: default;
}

.rows .row-group>.row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  color: #fff;
}

.rows .row-group>.row>div {
  display: table-cell;
  overflow: hidden;
  line-height: 1.2;
  vertical-align: text-bottom;
}

/* -- sizes -- */
.rows .row-group.row-group-2 {
  height: 20%;
}

.rows .row-group.row-group-10 {
  height: 100%;
}

/* -- bar -- */
.row-group-bar {
  position: absolute;
  bottom: 0;
  height: 10%;
  padding-left: 2vh;
}

.row-group-bar.row-group-bar-yellow {
  background: #ffbf51 !important;
}

.row-group-bar.row-group-bar-yellow .text-scroll-x {
  color: #003a79;
  font-style: normal !important;
}

.row-group-bar .col-first {
  width: auto !important;
  vertical-align: middle !important;
}

.row-group-bar .col-first .bar-informations {
  font-size: 7em;
}

.row-group-bar .col-second {
  width: 30vh;
  text-align: center;
  vertical-align: bottom !important;
}

/* -- selection -- */
::selection {
  color: white;
  background: #148e76;
}

::-moz-selection {
  color: white;
  background: #148e76;
}

.alert {
  position: relative;
  margin-bottom: 20px;
  padding: 20px 20px 20px 80px;
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 1.3;
  background: #e74c3c;
}

.alert.alert-flash {
  position: fixed;
  top: 7px;
  right: 0;
  margin-bottom: 0;
  z-index: 5000;
  animation: alert 4s ease forwards;
}

.alert.alert-success {
  background: #16a085;
}

.alert.alert-success .icon {
  background: #148e76;
}

.alert.alert-success .icon:before {
  content: '\e116';
}

.alert.alert-info {
  color: #878787;
  background: #ededed;
}

.alert.alert-info .icon {
  background: #e0e0e0;
}

.alert.alert-info .icon:before {
  content: '\e60a';
}

.alert .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  padding: 22px 0;
  font-family: 'icomoon';
  text-align: center;
  background: #d62c1a;
}

.alert .icon:before {
  content: '\e117';
}

@keyframes alert {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  10% {
    opacity: 1;
    transform: translateX(0%);
  }

  90% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.sidebar {
  position: fixed;
  z-index: 5001;
  height: 100%;
  overflow: hidden;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.4;
  background: #1c1d26;
  -moz-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
  -o-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
  -webkit-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
  transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
}

.sidebar.sidebar-left {
  width: 76px;
  left: -76px;
}

.sidebar.sidebar-left:hover {
  left: 0;
}

.sidebar.sidebar-left:hover~.rows {
  left: 76px;
}

.sidebar.sidebar-right {
  width: 450px;
  right: -450px;
}

.sidebar .sidebar-toggle,
.sidebar .sidebar-header {
  display: block;
  height: 76px;
  line-height: 76px;
  margin-bottom: 5px;
  text-align: center;
  background: #006f94;
}

.sidebar .sidebar-toggle.sidebar-toggle,
.sidebar .sidebar-header.sidebar-toggle {
  position: absolute;
  top: 0;
  z-index: 5000;
  width: 100%;
  font-size: 1.8em;
  visibility: visible;
  opacity: 1;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.sidebar .sidebar-inner {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-right: 20px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  -moz-transition: visibility 0s ease 0.2s;
  -o-transition: visibility 0s ease 0.2s;
  -webkit-transition: visibility 0s ease;
  -webkit-transition-delay: 0.2s;
  transition: visibility 0s ease 0.2s;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.sidebar .sidebar-inner li {
  width: 450px;
  position: relative;
}

.sidebar .sidebar-inner li.sidebar-header a {
  padding: 0;
  color: #fff;
  font-size: 1.3em;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: bold;
}

.sidebar .sidebar-inner li.sidebar-title {
  padding: 20px 40px;
  font-size: 1.2em;
  font-weight: 500;
}

.sidebar .sidebar-inner li a {
  display: block;
  padding: 15px 40px;
  color: rgba(250, 250, 250, 0.8);
  -moz-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.sidebar .sidebar-inner li a:hover,
.sidebar .sidebar-inner li a:focus {
  color: #fff;
}

.sidebar .sidebar-inner li span[class^="icon-"],
.sidebar .sidebar-inner li a.sidebar-btn {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 30px;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
}


/* -- sidebar-active -- */

.sidebar-active {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 76px;
  height: 100%;
}

.sidebar-active:hover~.sidebar-left {
  left: 0;
}

.sidebar-active:hover~.rows {
  left: 76px;
}


/* -- toggleSidebar -- */

.toggle-left .sidebar-left {
  left: 0 !important;
  width: 450px;
}

.toggle-left .sidebar-left .sidebar-toggle {
  visibility: hidden !important;
  opacity: 0;
  -moz-transition: visibility 0s ease 0.2s;
  -o-transition: visibility 0s ease 0.2s;
  -webkit-transition: visibility 0s ease;
  -webkit-transition-delay: 0.2s;
  transition: visibility 0s ease 0.2s;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.toggle-left .sidebar-left .sidebar-inner {
  visibility: visible;
  opacity: 1;
}

.toggle-left .sidebar-overlay {
  display: block;
}

.toggle-left .rows {
  left: 450px !important;
}

.toggle-right .sidebar-right {
  right: 0 !important;
}

.toggle-right .sidebar-right .sidebar-toggle {
  visibility: hidden !important;
  opacity: 0;
  -moz-transition: visibility 0s ease 0.2s;
  -o-transition: visibility 0s ease 0.2s;
  -webkit-transition: visibility 0s ease;
  -webkit-transition-delay: 0.2s;
  transition: visibility 0s ease 0.2s;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.toggle-right .sidebar-right .sidebar-inner {
  visibility: visible;
  opacity: 1;
}

.toggle-right .sidebar-overlay {
  display: block;
}

.toggle-right .rows {
  left: -450px !important;
}

/* -- informations -- */
.bar-informations {
  display: inline-block;
  white-space: nowrap;
  font-style: italic;
}

/* -- clock -- */
.bar-clock {
  display: inline-block;
  position: relative;
  z-index: 5000;
  width: 80%;
  height: 7.5vh;
  font-size: 5vh;
  font-weight: bold;
  line-height: 1.5;
  border: solid 0.5vh #fff;
  border-bottom: none;
  border-radius: 0.8vh 0.8vh 0 0;
  background: #003a79;
}

.bar-clock small {
  display: inline-block;
  padding-left: 1.6vh;
  color: #ff9f17;
}

/* -- blink -- */
.animation-blink {
  position: relative;
}

.animation-blink .animation-blink-1, .animation-blink .animation-blink-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  will-change: visibility;
  animation: blink 4s steps(2, start) infinite;
  -webkit-animation: blink 4s steps(2, start) infinite;
}

.animation-blink .animation-blink-2 {
  visibility: hidden;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

.animation-blink .animation-blink-clock {
  animation: blink 1.5s steps(2, start) infinite;
  -webkit-animation: blink 1.5s steps(2, start) infinite;
}

@keyframes blink {
  from {
    visibility: visible;
  }

  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  from {
    visibility: visible;
  }

  to {
    visibility: hidden;
  }
}

/* -- scroll-x -- */
.animation-scroll-x {
  transform: translateZ(0);
  will-change: transform;
  animation: scrollX 0s linear infinite;
  -webkit-animation: scrollX 0s linear infinite;
}

@keyframes scrollX {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes scrollX {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

/* -- scroll-y -- */
.row-group-informations .scroll-y {
  transform: translateZ(0);
  will-change: transform;
  position: absolute;
}

.row-group-train .scroll-y {
  transform: translateZ(0);
  will-change: transform;
  position: relative;
}

/* -- cols -- */
.rows .row-group.row-group-informations {
  height: 100%;
}

.rows .row-group-informations .row .col-first {
  width: 62vh;
  background: #003a79 no-repeat center right/contain;
}

.rows .row-group-informations .row .col-second {
  position: relative;
  background: #ffbf51;
}

/* -- bar -- */
.row-group-bar {
  background: transparent;
}

/* -- styles -- */
.rows .row-group.row-group-informations-1 .col-first {
  background-image: url(../../../img/screen/informations/row-background-informations-1.svg);
}

.rows .row-group.row-group-informations-2 .col-first {
  background-image: url(../../../img/screen/informations/row-background-informations-2.svg);
}

/* -- texts -- */
.text-informations {
  padding: 5vh 18vh;
  color: #003a79;
  font-size: 6.2em;
  line-height: 1.35;
}