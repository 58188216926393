/* -- compass -- */
/* -- sass files -- */
/* -- colors -- */
/* -- colors (screen) -- */
/* -- widths -- */
/* -- base -- */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,100italic,300italic");

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    overflow-x: hidden;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

body {
    font-family: 'Achemine';
    font-size: 1vh;
    font-weight: normal;
    line-height: 1;
}

*,
:after,
:before {
    outline: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:focus,
*:active,
:after:focus,
:after:active,
:before:focus,
:before:active {
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}

.rows {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    -moz-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
}

.rows .row-group {
    height: 10%;
}

.rows .row-group:hover {
    cursor: default;
}

.rows .row-group>.row {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    color: #fff;
}

.rows .row-group>.row>div {
    display: table-cell;
    overflow: hidden;
    line-height: 1.2;
    vertical-align: text-bottom;
}

.rows .row-group-hidden {
    display: none;
}


/* -- sizes -- */

.rows .row-group.row-group-2 {
    height: 20%;
}

.rows .row-group.row-group-10 {
    height: 100%;
}


/* -- bar -- */

.row-group-bar {
    position: absolute;
    bottom: 0;
    height: 10%;
    padding-left: 2vh;
}

.row-group-bar.row-group-bar-yellow {
    background: #ffbf51 !important;
}

.row-group-bar.row-group-bar-yellow .text-scroll-x {
    color: #003a79;
    font-style: normal !important;
}

.row-group-bar.row-group-bar-red {
    background: #cd0037 !important;
}

.row-group-bar.row-group-bar-red .text-scroll-x {
    color: #ffffff;
    font-style: normal !important;
}

.row-group-bar.row-group-bar-purple {
    background: #50077D !important;
}

.row-group-bar.row-group-bar-purple .text-scroll-x {
    color: #ffffff;
    font-style: normal !important;
}

.row-group-bar.row-group-bar-black {
    background: #000000;
}

.row-group-bar.row-group-bar-black .text-scroll-x {
    color: #ffffff;
    font-style: normal !important;
}

.row-group-bar .col-first {
    width: auto !important;
    vertical-align: middle !important;
}

.row-group-bar .col-first .bar-informations {
    font-size: 7em;
}

.row-group-bar .col-first .bar-informations {
    font-size: 7em;
}

.row-group-bar .col-second {
    width: 30vh;
    text-align: center;
    vertical-align: bottom !important;
}


/* -- selection -- */

::selection {
    color: white;
    background: #006f94;
}

::-moz-selection {
    color: white;
    background: #006f94;
}

.alert {
    position: relative;
    margin-bottom: 20px;
    padding: 20px 20px 20px 80px;
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 1.3;
    background: #e74c3c;
}

.alert.alert-flash {
    position: fixed;
    top: 7px;
    right: 0;
    margin-bottom: 0;
    z-index: 5000;
    animation: alert 4s ease forwards;
}

.alert.alert-success {
    background: #006f94;
}

.alert.alert-success .icon {
    background: #006f94;
}

.alert.alert-success .icon:before {
    content: '\e116';
}

.alert.alert-info {
    color: #878787;
    background: #ededed;
}

.alert.alert-info .icon {
    background: #e0e0e0;
}

.alert.alert-info .icon:before {
    content: '\e60a';
}

.alert .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    padding: 22px 0;
    font-family: 'icomoon';
    text-align: center;
    background: #d62c1a;
}

.alert .icon:before {
    content: '\e117';
}

@keyframes alert {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    10% {
        opacity: 1;
        transform: translateX(0%);
    }

    90% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

.sidebar {
    position: fixed;
    z-index: 5001;
    height: 100%;
    overflow: hidden;
    color: #fff;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.4;
    background: #1c1d26;
    -moz-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    -o-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    -webkit-transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
    transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
}

.sidebar.sidebar-left {
    width: 76px;
    left: -76px;
}

.sidebar.sidebar-left:hover {
    left: 0;
}

.sidebar.sidebar-left:hover~.rows {
    left: 76px;
}

.sidebar.sidebar-right {
    width: 450px;
    right: -450px;
}

.sidebar .sidebar-toggle,
.sidebar .sidebar-header {
    display: block;
    height: 76px;
    line-height: 76px;
    margin-bottom: 5px;
    text-align: center;
    background: #006f94;
}

.sidebar .sidebar-toggle.sidebar-toggle,
.sidebar .sidebar-header.sidebar-toggle {
    position: absolute;
    top: 0;
    z-index: 5000;
    width: 100%;
    font-size: 1.8em;
    visibility: visible;
    opacity: 1;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.sidebar .sidebar-inner {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.sidebar .sidebar-inner li {
    width: 450px;
    position: relative;
}

.sidebar .sidebar-inner li.sidebar-header a {
    padding: 0;
    color: #fff;
    font-size: 1.3em;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: bold;
}

.sidebar .sidebar-inner li.sidebar-title {
    padding: 20px 40px;
    font-size: 1.2em;
    font-weight: 500;
}

.sidebar .sidebar-inner li a {
    display: block;
    padding: 15px 40px;
    color: rgba(250, 250, 250, 0.8);
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.sidebar .sidebar-inner li a:hover,
.sidebar .sidebar-inner li a:focus {
    color: #fff;
}

.sidebar .sidebar-inner li span[class^="icon-"],
.sidebar .sidebar-inner li a.sidebar-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 30px;
}

.sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
}


/* -- sidebar-active -- */

.sidebar-active {
    position: fixed;
    z-index: 4000;
    top: 0;
    left: 0;
    width: 76px;
    height: 100%;
}

.sidebar-active:hover~.sidebar-left {
    left: 0;
}

.sidebar-active:hover~.rows {
    left: 76px;
}


/* -- toggleSidebar -- */

.toggle-left .sidebar-left {
    left: 0 !important;
    width: 450px;
}

.toggle-left .sidebar-left .sidebar-toggle {
    visibility: hidden !important;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.toggle-left .sidebar-left .sidebar-inner {
    visibility: visible;
    opacity: 1;
}

.toggle-left .sidebar-overlay {
    display: block;
}

.toggle-left .rows {
    left: 450px !important;
}

.toggle-right .sidebar-right {
    right: 0 !important;
}

.toggle-right .sidebar-right .sidebar-toggle {
    visibility: hidden !important;
    opacity: 0;
    -moz-transition: visibility 0s ease 0.2s;
    -o-transition: visibility 0s ease 0.2s;
    -webkit-transition: visibility 0s ease;
    -webkit-transition-delay: 0.2s;
    transition: visibility 0s ease 0.2s;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.toggle-right .sidebar-right .sidebar-inner {
    visibility: visible;
    opacity: 1;
}

.toggle-right .sidebar-overlay {
    display: block;
}

.toggle-right .rows {
    left: -450px !important;
}


/* -- informations -- */

.bar-informations {
    display: inline-block;
    white-space: nowrap;
    font-style: italic;
}


/* -- clock -- */

.bar-clock {
    display: inline-block;
    position: relative;
    z-index: 5000;
    width: 80%;
    height: 7.5vh;
    font-size: 5vh;
    font-weight: 700;
    line-height: 1.5;
    border: solid 0.5vh #fff;
    border-bottom: none;
    border-radius: 0.8vh 0.8vh 0 0;
    background: #003a79;
}

.bar-clock small {
    display: inline-block;
    color: #ff9f17;
}


/* -- blink -- */

.animation-blink {
    position: relative;
}

.animation-blink .animation-blink-1,
.animation-blink .animation-blink-2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    will-change: visibility;
    animation: blink 4s steps(2, start) infinite;
    -webkit-animation: blink 4s steps(2, start) infinite;
}

.animation-blink .animation-blink-2 {
    visibility: hidden;
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
}

.animation-blink .animation-blink-clock {
    animation: blink 1.5s steps(2, start) infinite;
    -webkit-animation: blink 1.5s steps(2, start) infinite;
}

@keyframes blink {
    from {
        visibility: visible;
    }

    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink {
    from {
        visibility: visible;
    }

    to {
        visibility: hidden;
    }
}

/* -- provenance in departures -- */

.scroll-provenance {
    animation: scrollstop 3s linear infinite;
    -webkit-animation: scrollstop 3s linear infinite;
}

@-webkit-keyframes scrollstop {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes scrollstop {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

/* -- scroll-x -- */

.animation-scroll-x {
    transform: translateZ(0);
    will-change: transform;
    animation: scrollX 0s linear infinite;
    -webkit-animation: scrollX 0s linear infinite;
}

@keyframes scrollX {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes scrollX {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}


@keyframes scrollY {

    /* 0% { transform: translateY(100%); }
    25% { transform: translateY(-100%); }
    50% { transform: translateY(100%); }
    75% { transform: translateY(0%); }
    100% { transform: translateY(-100%); } */
    from {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(100%);
    }

    to {
        transform: translateY(100%);
    }
}

@-webkit-keyframes scrollY {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(100%);
        animation-timing-function: ease-in-out
    }
}

/* -- scroll-y -- */

.row-group-informations .scroll-y {
    transform: translateZ(0);
    will-change: transform;
    position: absolute;
}

.row-group-train .scroll-y {
    transform: translateZ(0);
    will-change: transform;
    position: relative;
}


/* -- texts -- */

.text-scroll-x {
    display: inline-block;
    padding-left: 100%;
    color: rgba(255, 255, 255, 0.7);
    font-size: 8em;
    white-space: nowrap;
}

.text-scroll-x span {
    display: inline;
}

.text-scroll-x span:after {
    content: '';
    display: inline-block;
    width: 2.5vh;
    height: 2.5vh;
    margin: 0 1.5vh;
    margin-bottom: 0.5vh;
    border-radius: 50%;
    background: #f5f900;
}

.text-scroll-x span:last-child:after {
    display: none;
}

.text-type {
    line-height: 1;
    font-size: 3.3em;
    font-weight: 100;
    font-family: 'Achemine';
    display: inline-block;
}

.text-number {
    line-height: 1;
    font-size: 4em;
    font-weight: bold;
    display: inline-block;
}

.text-time {
    color: #f5f900;
    font-size: 6.5em;
    font-weight: bold;
}

.text-time.minutes {
    font-size: 5em;
}

.text-time.retard {
    color: #f9a200;
}

.text-features-1 {
    margin-top: 2%;
    font-size: 3.5em;
    font-weight: bold;
}

.text-features-2 {
    color: #fff;
    font-size: 0.8em;
    font-weight: bold;
}

.text-features-3 {
    color: #f5f900;
    font-size: 3.5em;
    font-weight: bold;
}


/* -- background -- */

.row-background {
    position: absolute;
    right: 5vh;
    bottom: 10vh;
    width: 23vh;
    height: 90%;
    background-repeat: no-repeat;
}


/* -- cols -- */

.row-group {
    height: 10%;
}

.row-group .row {
    position: relative;
}

.row-group .row .col-first {
    width: 17vh;
}

.row-group .row .col-first-details {
    display: relative;
    color: #f5f900;
    width: 60vh;
    font-size: 5em;
    text-align: center;
    white-space: nowrap;
}

.row-group .row .col-second {
    position: relative;
}

.row-group .row .col-second-first {
    width: 20vh;
    vertical-align: middle !important;
}

.row-group .row .col-second-second {
    width: 24vh;
}

.row-group .row .col-second-third {
    font-size: 8em;
    white-space: nowrap;
}

.row-group .row .col-second-merged {
    width: 44vh;
}

.row-group .row .col-second-merged>div {
    display: table;
}

.row-group .row .col-second-merged>div>div {
    display: table-cell;
    vertical-align: middle;
}

.row-group .row .col-hide {
    display: block;
    position: relative;
    width: 0;
    overflow: visible !important;
}

.row-group .row .col-hide .col-hide-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1vh;
    width: 100vh;
    overflow: hidden;
}

.row-group .row .col-third {
    position: relative;
    z-index: 1;
    width: 18vh;
    overflow: visible !important;
}


/* -- sizes -- */

.rows .row-group-2 .row:nth-of-type(1) {
    height: 55%;
}

.rows .row-group-2 .row:nth-of-type(1)>div {
    line-height: 1;
}

.rows .row-group-2 .row:nth-of-type(1) .col-second-first {
    vertical-align: text-bottom !important;
}

.rows .row-group-2 .row:nth-of-type(1) .col-second-first .animation-blink {
    height: 6vh;
}

.rows .row-group-2 .row:nth-of-type(1) .col-second-third {
    font-size: 10em;
}

.rows .row-group-2 .row:nth-of-type(1) .col-second-third .animation-rotate {
    height: 11vh;
}

.rows .row-group-2 .row:nth-of-type(1) .col-second-third .animation-rotate div {
    margin-bottom: 1vh;
}

.rows .row-group-2 .row:nth-of-type(1) .text-time {
    font-size: 7.5em;
}

.rows .row-group-2 .row:nth-of-type(1) .text-time.minutes {
    font-size: 5em;
}

.rows .row-group-2 .row:nth-of-type(1) .train-track {
    height: 11vh;
    width: 11vh;
    font-size: 7.5em;
    line-height: 1.4;
}

.rows .row-group-2 .row:nth-of-type(1) .train-track.train-track-p:before {
    width: 10.25vh;
    height: 10.25vh;
}

.rows .row-group-2 .row:nth-of-type(1) .train-track.train-track-h:not(.train-track-view) {
    line-height: 0.4;
}

.rows .row-group-2 .row:nth-of-type(2) {
    height: 45%;
}

.rows .row-group-2 .row:nth-of-type(2)>div {
    line-height: 1.05;
}


/* -- styles -- */

.rows.rows-departures.scroll-y {
    transform: translateZ(0);
    will-change: transform;
    animation: scrollY 10s linear infinite;
    -webkit-animation: scrollY 10s linear infinite;
}

.rows.rows-departures,
.rows.rows-transilien {
    background: #0064aa;
}

.rows.rows-departures .row-group:nth-of-type(odd),
.rows.rows-transilien .row-group:nth-of-type(odd) {
    background: #0064aa;
}

.rows.rows-departures .row-group:nth-of-type(odd) .train-track-h:not(.train-track-view),
.rows.rows-transilien .row-group:nth-of-type(odd) .train-track-h:not(.train-track-view) {
    color: #0064aa;
}

.rows.rows-departures .row-group:nth-of-type(even),
.rows.rows-transilien .row-group:nth-of-type(even) {
    background: #003a79;
}

.rows.rows-departures .row-group:nth-of-type(even) .train-track-h:not(.train-track-view),
.rows.rows-transilien .row-group:nth-of-type(even) .train-track-h:not(.train-track-view) {
    color: #003a79;
}

.rows.rows-departures .row-background,
.rows.rows-transilien .row-background {
    background-image: url(../../../img/screen/departure/row-background-departures.svg);
}

.rows.rows-arrivals {
    background: #217a42;
}

.rows.rows-arrivals .row-group:nth-of-type(odd) {
    background: #217a42;
}

.rows.rows-arrivals .row-group:nth-of-type(odd) .train-track-h:not(.train-track-view) {
    color: #217a42;
}

.rows.rows-arrivals .row-group:nth-of-type(even) {
    background: #0c5639;
}

.rows.rows-arrivals .row-group:nth-of-type(even) .train-track-h:not(.train-track-view) {
    color: #0c5639;
}

.rows.rows-arrivals .row-background {
    background-image: url(../../../img/screen/arrives/row-background-arrivals.svg);
}

.rows.rows-error {
    background: #ec0e0e;
}

.rows.rows-error .row-group:nth-of-type(odd) {
    background: #a00e0e;
}


/* -- logo -- */

.train-logo {
    height: 6vh;
    background: no-repeat center;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

.train-logo.train-logo-sncf {
    background-image: url(../../../img/screen/common/logo-sncf.svg);
}

.train-logo.train-logo-inoui {
    background-image: url(../../../img/screen/common/logo-inoui.svg);
}

.train-logo.train-logo-ter {
    background-image: url(../../../img/screen/common/logo-ter.svg);
}

.train-logo.train-logo-tgv {
    background-image: url(../../../img/screen/common/logo-tgv.svg);
}

.train-logo.train-logo-ice {
    background-image: url(../../../img/screen/common/logo-ice.svg);
}

.train-logo.train-logo-lyria {
    background-image: url(../../../img/screen/common/logo-lyria.svg);
}

.train-logo.train-logo-ouigo {
    background-image: url(../../../img/screen/common/logo-ouigo.svg);
}

.train-logo.train-logo-ouigo-classique {
    background-image: url(../../../img/screen/common/logo-ouigo-classique.svg);
}

.train-logo.train-logo-ler {
    background-image: url(../../../img/screen/common/logo-ler.svg);
}

.train-logo.train-logo-fluo {
    background-image: url(../../../img/screen/common/logo-fluo.svg);
}

.train-logo.train-logo-occitanie {
    background-image: url(../../../img/screen/common/logo-occitanie.svg);
}

.train-logo.train-logo-intercite {
    background-image: url(../../../img/screen/common/logo-intercites.svg);
    margin-left: 10%;
    margin-right: 10%;
}

.train-logo.train-logo-aleop {
    background-image: url(../../../img/screen/common/logo-aleop.svg);
}

.train-logo.train-logo-auvergne-rhone-alpes {
    background-image: url(../../../img/screen/common/logo-auvergerhonealpes.svg);
}

.train-logo.train-logo-breizhgo {
    background-image: url(../../../img/screen/common/logo-breizhgo.svg);
}

.train-logo.train-logo-db {
    background-image: url(../../../img/screen/common/logo-db.svg);
}

.train-logo.train-logo-ter-hauts-de-france {
    background-image: url(../../../img/screen/common/logo-hautsdefrance.svg);
}

.train-logo.train-logo-lio {
    background-image: url(../../../img/screen/common/logo-lio.svg);
}

.train-logo.train-logo-ter-metrolor {
    background-image: url(../../../img/screen/common/logo-metrolor.svg);
}

.train-logo.train-logo-mobigo {
    background-image: url(../../../img/screen/common/logo-mobigo.svg);
}

.train-logo.train-logo-nomad {
    background-image: url(../../../img/screen/common/logo-nomad.svg);
}

.train-logo.train-logo-remi {
    background-image: url(../../../img/screen/common/logo-remi.svg);
}

.train-logo.train-logo-renfe-ave {
    background-image: url(../../../img/screen/common/logo-renfeave.svg);
}

.train-logo.train-logo-sbb {
    background-image: url(../../../img/screen/common/logo-sbb.svg);
}

.train-logo.train-logo-sncf-1985 {
    background-image: url(../../../img/screen/common/logo-sncf1985.svg);
}

.train-logo.train-logo-sncf-1992 {
    background-image: url(../../../img/screen/common/logo-sncf1992.svg);
}

.train-logo.train-logo-ter-alsace {
    background-image: url(../../../img/screen/common/logo-teralsace.svg);
}

.train-logo.train-logo-ter-aquitaine {
    background-image: url(../../../img/screen/common/logo-teraquitaine.svg);
}

.train-logo.train-logo-ter-basse-normandie {
    background-image: url(../../../img/screen/common/logo-terbassenormandie.svg);
}

.train-logo.train-logo-ter-bourgogne {
    background-image: url(../../../img/screen/common/logo-terbourgogne.svg);
}

.train-logo.train-logo-ter-bretagne {
    background-image: url(../../../img/screen/common/logo-terbretagne.svg);
}

.train-logo.train-logo-ter-centre {
    background-image: url(../../../img/screen/common/logo-tercentre.svg);
}

.train-logo.train-logo-ter-languedoc-roussillon {
    background-image: url(../../../img/screen/common/logo-terlanguedocroussillon.svg);
}

.train-logo.train-logo-ter-midi-pyrenees {
    background-image: url(../../../img/screen/common/logo-termidipyrenees.svg);
}

.train-logo.train-logo-ter-nord-pas-de-calais {
    background-image: url(../../../img/screen/common/logo-ternordpasdecalais.svg);
}

.train-logo.train-logo-poitou-charentes {
    background-image: url(../../../img/screen/common/logo-terpoitoucharentes.svg);
}

.train-logo.train-logo-thello {
    background-image: url(../../../img/screen/common/logo-thello.svg);
}

.train-logo.train-logo-tram-train {
    background-image: url(../../../img/screen/common/logo-tramtrain.svg);
}

.train-logo.train-logo-zou {
    background-image: url(../../../img/screen/common/logo-zou.svg);
}

.train-logo.train-logo-eurostar {
    background-image: url(../../../img/screen/common/logo-eurostar.svg);
}

.train-logo.train-logo-thalys {
    background-image: url(../../../img/screen/common/logo-thalys.svg);
}

.train-logo.train-logo-rer-a {
    background-image: url(../../../img/screen/common/logo-rer-a.svg);
}

.train-logo.train-logo-rer-b {
    background-image: url(../../../img/screen/common/logo-rer-b.svg);
}

.train-logo.train-logo-rer-c {
    background-image: url(../../../img/screen/common/logo-rer-c.svg);
}

.train-logo.train-logo-rer-d {
    background-image: url(../../../img/screen/common/logo-rer-d.svg);
}

.train-logo.train-logo-rer-e {
    background-image: url(../../../img/screen/common/logo-rer-e.svg);
}

.train-logo.train-logo-t-h {
    background-image: url(../../../img/screen/common/logo-t-h.svg);
}

.train-logo.train-logo-t-j {
    background-image: url(../../../img/screen/common/logo-t-j.svg);
}

.train-logo.train-logo-t-k {
    background-image: url(../../../img/screen/common/logo-t-k.svg);
}

.train-logo.train-logo-t-l {
    background-image: url(../../../img/screen/common/logo-t-l.svg);
}

.train-logo.train-logo-t-n {
    background-image: url(../../../img/screen/common/logo-t-n.svg);
}

.train-logo.train-logo-t-p {
    background-image: url(../../../img/screen/common/logo-t-p.svg);
}

.train-logo.train-logo-t-r {
    background-image: url(../../../img/screen/common/logo-t-r.svg);
}

.train-logo.train-logo-t-u {
    background-image: url(../../../img/screen/common/logo-t-u.svg);
}

.train-logo.train-logo-lunea {
    background-image: url(../../../img/screen/common/logo-lunea.svg);
}

.train-logo.train-logo-teoz {
    background-image: url(../../../img/screen/common/logo-teoz.svg);
}

.train-logo.train-logo-frecciarossa {
    background-image: url(../../../img/screen/common/logo-frecciarossa.svg);
}

.train-logo.train-logo-trenitalia {
    background-image: url(../../../img/screen/common/logo-trenitalia.svg);
}

.train-logo.train-logo-cfl {
    background-image: url(../../../img/screen/common/logo-cfl.svg);
}

.train-logo.train-logo-sncb {
    background-image: url(../../../img/screen/common/logo-sncb.svg);
}


/* -- rer --*/

.rer.train-lng {
    height: 75%;
    background: no-repeat center;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
}

.rer.train-lng.train-long {
    background-image: url(../../../img/screen/rer/train_long.png);
}

.rer.train-lng.train-court {
    background-image: url(../../../img/screen/rer/train_court.png);
}

.rer-hour-mode {
    font-size: 4.75em;
}

/* -- type - number -- */

.train-type-number {
    display: inline-block;
}


/* -- information-dynamic -- */

.train-information-dynamic {
    position: absolute;
    right: 0;
    top: 0.7vh;
    bottom: 0.7vh;
    width: 55vh;
    padding-left: 2vh;
    font-size: 4em;
    line-height: 1;
    border-left: 0.5vh #fff solid;
}

.row-group:nth-of-type(odd) .train-information-dynamic {
    background-color: #0064aa;
}

.row-group:nth-of-type(even) .train-information-dynamic {
    background-color: #003a79;
}

.train-information-dynamic.train-information-dynamic-yellow {
    color: #f5f900;
    border-color: #f5f900;
}

.train-information-dynamic-station {
    display: block;
    width: 100%;
    overflow: hidden;
}


/* -- track -- */

.train-track {
    display: none;
    position: absolute;
    top: 0.75vh;
    right: 3vh;
    width: 8vh;
    height: 8vh;
    font-size: 5.6em;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    border: solid 0.35vh #fff;
    border-radius: 10%;
    z-index: 1000;
}

.train-track span {
    display: none;
}

.train-track.train-track-view {
    display: block;
}

.train-track.train-track-view span {
    display: inline;
}

.train-track.train-track-view h1,
.train-track.train-track-view small {
    display: none;
}

.train-track.train-track-p {
    display: block;
}

.train-track.train-track-p.train-track-view {
    background: transparent !important;
}

.train-track.train-track-p.train-track-view:before {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 7.25vh;
    height: 7.25vh;
}

.train-track.train-track-p.train-track-view.train-track-y:before {
    box-shadow: 1.25vh 1.25vh 0 #f5f900;
}

.train-track.train-track-p.train-track-view.train-track-b:before {
    box-shadow: 1.25vh 1.25vh 0 #115db6;
}

.train-track.train-track-p.train-track-y {
    background: #f5f900;
}

.train-track.train-track-p.train-track-b {
    background: #115db6;
}

.train-track.train-track-h:not(.train-track-view) {
    display: block;
    line-height: 0.4;
    background: #fff;
}

.train-track.train-track-h:not(.train-track-view) h1 {
    margin: 0;
    font-size: 0.75em;
}

.train-track.train-track-h:not(.train-track-view) small {
    font-size: 0.4em;
}

.train-track.train-track-view.noview {
    border: dashed 0.35vh #fff;
}

.train-track.train-track-car {
    border: none;
    background: url(../../../img/screen/common/track-car.svg) no-repeat center;
    background-size: cover;
}


/* -- rotate -- */

.animation-rotate {
    height: 10vh;
    overflow: hidden;
    transform: translateZ(0);
    will-change: transform;
}

.animation-rotate .animation-rotate-inner {
    height: 100%;
    animation: rotate 9s linear infinite;
    -webkit-animation: rotate 9s linear infinite;
}

.animation-rotate .animation-rotate-inner div {
    margin-bottom: 0.5vh;
}

@keyframes rotate {
    33% {
        transform: translateY(0%);
    }

    50%,
    83.33% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(-200%);
    }
}

@-webkit-keyframes rotate {
    33% {
        transform: translateY(0%);
    }

    50%,
    83.33% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(-200%);
    }
}


/* -- dynamic -- */

.animation-dynamic {
    transform: translateX(100%) translateZ(0);
    will-change: transform;
    animation: dynamic 13s linear infinite;
    -webkit-animation: dynamic 13s linear infinite;
}

@keyframes dynamic {
    0% {
        transform: translateX(100%) translateZ(0);
    }

    20%,
    60% {
        transform: translateX(0%) translateZ(0);
    }

    80% {
        transform: translateX(100%) translateZ(0);
    }
}

@-webkit-keyframes dynamic {
    0% {
        transform: translateX(100%) translateZ(0);
    }

    20%,
    60% {
        transform: translateX(0%) translateZ(0);
    }

    80% {
        transform: translateX(100%) translateZ(0);
    }
}


/* -- animation-dynamic-station -- */

.animation-dynamic-station {
    width: calc(100% - 0vh);
    transform: translateZ(0);
    will-change: width;
    animation: dynamic_station 13s linear infinite;
    -webkit-animation: dynamic_station 13s linear infinite;
}

@keyframes dynamic_station {
    0% {
        width: calc(100% - 0vh);
    }

    20%,
    60% {
        width: calc(100% - 55vh);
    }

    80% {
        width: calc(100% - 0vh);
    }
}

@-webkit-keyframes dynamic_station {
    0% {
        width: calc(100% - 0vh);
    }

    20%,
    60% {
        width: calc(100% - 55vh);
    }

    80% {
        width: calc(100% - 0vh);
    }
}